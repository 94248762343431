<template>
  
  <vue-good-table
    mode="remote"
    @on-search="onSearch"
    @on-page-change="onPageChange"
    @on-sort-change="onSortChange"
    @on-column-filter="onColumnFilter"
    @on-selected-rows-change="onSelectChange"
    :totalRows="totalRecords"
    :isLoading.sync="isLoading"
    :columns="columns"
    :rows="recipients"
    :search-options="{
      enabled: true,
      placeholder: 'Search for an recipient...',
    }"
    :pagination-options="{
      enabled: true,
      perPage: 50,
      dropdownAllowAll: false,
      perPageDropdownEnabled: false,
    }"
    :select-options="{
      enabled: true,
      disableSelectInfo: true,
    }">
  >
    <template slot="table-row" slot-scope="props">
      <router-link :to="`/projects/${projectId}/recipients/${props.row.id}`" v-if="props.column.field == 'username'">
        <p class="flex items-center">
          <img v-if="props.row.has_profile_picture" :src="`${imageUrl}/${props.row.id}/profile-picture.jpg`" loading="lazy" class="w-6 h-6 rounded-full mr-3 bg-gray-100">
          <span v-if="!props.row.has_profile_picture" class="w-6 h-6 rounded-full mr-3 bg-gray-100"></span>
          {{ props.row.username }}
        </p>
      </router-link>
      <div v-else-if="props.column.field == 'state'">
        <span class="bg-yellow-100 text-yellow-700 text-base font-semibold text-center block rounded-lg" v-if="props.row.state === 'RECEIVER_ADDED'">
          Queued for scraping
        </span>
        <span class="bg-blue-100 text-blue-700 text-base font-semibold text-center block rounded-lg" v-else-if="props.row.state === 'RECEIVER_SCRAPED'">
          Queued for delivery
        </span>
        <span class="bg-red-100 text-red-700 text-base font-semibold text-center block rounded-lg" v-else-if="props.row.state === 'RECEIVER_NOT_FOUND'">
          Profile not found
        </span>
        <span class="bg-green-100 text-green-700 text-base font-semibold text-center block rounded-lg" v-else-if="props.row.state === 'MESSAGE_DELIVERED'">
          Message delivered
        </span>
        <span class="bg-red-100 text-red-700 text-base font-semibold text-center block rounded-lg" v-else-if="props.row.state === 'MESSAGE_RETRYING' || props.row.state === 'MESSAGE_FAILED'">
          Message not delivered
        </span>
      </div>
      <span v-else>
        {{props.formattedRow[props.column.field]}}
      </span>
    </template>
  </vue-good-table>
</template>

<script>
import axios from 'axios'

export default {
  props: ['projectId'],

  data () {
    return {
      imageUrl: `${process.env.VUE_APP_API_URL}/projects/recipient/file`,

      isLoading: false,
      totalRecords: 0,

      params: {
        page: 1,
        sort: {
          field: 'created_at', 
          type: 'asc',
        },
        search: null,
      },
      
      columns: [
        {
          label: 'Username',
          field: 'username',
          tdClass: 'vgt-left-align',
        },
        {
          label: 'Full Name',
          field: 'fullname',
          tdClass: 'vgt-left-align',
        },
        {
          label: 'State',
          field: 'state',
          width: '212px',
          tdClass: 'vgt-left-align',
        },
        {
          label: 'Created at',
          field: 'created_at',
          width: '130px',
          formatFn: value => { return this.$options.filters.formatDate(value, 'dd/MM HH:mm'); },
          thClass: 'vgt-left-align',
          tdClass: 'vgt-left-align',
        },
        {
          label: 'Updated at',
          field: 'updated_at',
          width: '130px',
          formatFn: value => { return this.$options.filters.formatDate(value, 'dd/MM HH:mm'); },
          thClass: 'vgt-left-align',
          tdClass: 'vgt-left-align',
        },
        {
          label: '# Posts',
          field: 'posts_count',
          type: 'number',
          width: '130px',
          formatFn: this.formatNumber,
          thClass: 'vgt-left-align',
          tdClass: 'vgt-left-align',
        },
        {
          label: '# Followers',
          field: 'followers_count',
          type: 'number',
          width: '130px',
          formatFn: this.formatNumber,
          thClass: 'vgt-left-align',
          tdClass: 'vgt-left-align',
        },
        {
          label: '# Following', field: 'following_count',
          type: 'number',
          width: '130px',
          formatFn: this.formatNumber,
          thClass: 'vgt-left-align',
          tdClass: 'vgt-left-align',
        },
      ],
      recipients: [],
    }
  },
  created () {
    this.loadItems();
  },
  methods: {
    updateParams(newProps) {
      this.params = Object.assign({}, this.params, newProps);
    },
    
    onPageChange(params) {
      this.updateParams({page: params.currentPage});
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: {
          type: params[0].type,
          field: params[0].field,
        },
      });
      this.loadItems();
    },
    
    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    onSelectChange(table) {
      let selectedIds = [];

      table.selectedRows.forEach(row => {
          selectedIds.push(row.id);
      });

      this.selected = selectedIds;
    },

    onSearch(params) {
      this.updateParams({search: params.searchTerm});
      this.loadItems();
    },

    getSelectedRows() {
      return this.selected;
    },

    loadItems() {
      axios
        .get(`${process.env.VUE_APP_API_URL}/projects/recipients/${this.projectId}?page=${this.params.page}&sort=${this.params.sort.field}&type=${this.params.sort.type}${this.params.search ? `&search=${this.params.search}` : ''}`)
        .then(response => {
          this.recipients = response.data.data;
          this.totalRecords = response.data.total;
        })
        .catch(error => {
          console.log(error)
        });
    },

    formatNumber: function(value) {
      if (!value) {
          return '0';
      } else {
          return value.toLocaleString('da-DK');
      }
    }
  }
}
</script>
