var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('vue-good-table',{attrs:{"mode":"remote","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"columns":_vm.columns,"rows":_vm.recipients,"search-options":{
    enabled: true,
    placeholder: 'Search for an recipient...',
  },"pagination-options":{
    enabled: true,
    perPage: 50,
    dropdownAllowAll: false,
    perPageDropdownEnabled: false,
  },"select-options":{
    enabled: true,
    disableSelectInfo: true,
  }},on:{"on-search":_vm.onSearch,"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-selected-rows-change":_vm.onSelectChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'username')?_c('router-link',{attrs:{"to":("/projects/" + _vm.projectId + "/recipients/" + (props.row.id))}},[_c('p',{staticClass:"flex items-center"},[(props.row.has_profile_picture)?_c('img',{staticClass:"w-6 h-6 rounded-full mr-3 bg-gray-100",attrs:{"src":(_vm.imageUrl + "/" + (props.row.id) + "/profile-picture.jpg"),"loading":"lazy"}}):_vm._e(),(!props.row.has_profile_picture)?_c('span',{staticClass:"w-6 h-6 rounded-full mr-3 bg-gray-100"}):_vm._e(),_vm._v(" "+_vm._s(props.row.username)+" ")])]):(props.column.field == 'state')?_c('div',[(props.row.state === 'RECEIVER_ADDED')?_c('span',{staticClass:"bg-yellow-100 text-yellow-700 text-base font-semibold text-center block rounded-lg"},[_vm._v(" Queued for scraping ")]):(props.row.state === 'RECEIVER_SCRAPED')?_c('span',{staticClass:"bg-blue-100 text-blue-700 text-base font-semibold text-center block rounded-lg"},[_vm._v(" Queued for delivery ")]):(props.row.state === 'RECEIVER_NOT_FOUND')?_c('span',{staticClass:"bg-red-100 text-red-700 text-base font-semibold text-center block rounded-lg"},[_vm._v(" Profile not found ")]):(props.row.state === 'MESSAGE_DELIVERED')?_c('span',{staticClass:"bg-green-100 text-green-700 text-base font-semibold text-center block rounded-lg"},[_vm._v(" Message delivered ")]):(props.row.state === 'MESSAGE_RETRYING' || props.row.state === 'MESSAGE_FAILED')?_c('span',{staticClass:"bg-red-100 text-red-700 text-base font-semibold text-center block rounded-lg"},[_vm._v(" Message not delivered ")]):_vm._e()]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_vm._v(" > ")])}
var staticRenderFns = []

export { render, staticRenderFns }