<template>
  <div>
    
    <!-- Header -->
    <div class="grid grid-cols-2">
      <div class="justify-self-start">
        <h1>Recipients</h1>
      </div>
      <div class="justify-self-end">
        <a :href="`${csvUrl}`" class="p-2 pr-3 pl-3 mr-4 text-white font-light bg-gray-500 rounded inline-block">
          Export as CSV
        </a>
        <router-link :to="`/projects/${projectId}/recipients/import`" class="p-2 pr-3 pl-3 mr-4 text-white font-light bg-gray-700 rounded inline-block">
          Import recipients
        </router-link>
        <button class="p-2 pr-3 pl-3 mr-4 text-white font-light bg-yellow-500 rounded inline-block" @click="resetReceivers">
          Reset selected
        </button>
        <button class="p-2 pr-3 pl-3 text-white font-light bg-red-500 rounded inline-block" @click="deleteReceivers">
          Delete selected
        </button>
      </div>
    </div>

    <recipient-table ref="recipient-table" :projectId="projectId" />
    
  </div>
</template>

<script>
import axios from 'axios';
import RecipientTable from '@/components/projects/recipients/RecipientsTable';

export default {
  props: ['projectId'],

  components: {
    RecipientTable
  },
  data() {
    return {
      csvUrl: `${process.env.VUE_APP_API_URL}/projects/recipients/${this.projectId}/csv`,
    }
  },
  methods: {
    resetReceivers() 
    {
      const recipients = this.$refs['recipient-table'].getSelectedRows();

      if (!recipients) return;

      if (confirm(`Are you sure you want to reset ${recipients.length} recipient(s)?\n\nAll associated data will be removed and needs to be scraped again.`))
      {
        axios.put(`${process.env.VUE_APP_API_URL}/projects/recipients/${this.projectId}/reset`, {
          ids: recipients,
        })
        .then(() => { 
          this.$router.go();
        })
        .catch(error => {
          console.log(error)
        });
      }
    },
    deleteReceivers() 
    {
      const recipients = this.$refs['recipient-table'].getSelectedRows();
      
      if (!recipients) return;

      if (confirm(`Are you sure you want to delete ${recipients.length} recipient(s)?`))
      {
        axios.delete(`${process.env.VUE_APP_API_URL}/projects/recipients/${this.projectId}`, {
          data: { 
            ids: recipients,
          }
        })
        .then(() => {
          this.$router.go();
        })
        .catch(error => {
          console.log(error)
        });
      }
    },
  }
}
</script>
